<template>
    <button class="y-button">
        <slot></slot>
    </button>
</template>
<script lang="ts" setup>

</script>
<style lang="scss" scoped>
.y-button {
  background: var(--app-color-bg);
  color: var(--app-text-color);
  padding: 8px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--app-border-color);
  transition: all .3s;

  &:focus,
  &:focus-visible {
    outline: auto 2px Highlight;
    outline: 4px auto -webkit-focus-ring-color;
  }

  &:active {
    cursor: pointer;
    background-color: var(--app-color-bg__hover);
  }

  @media (any-hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: var(--app-color-bg__hover);
    }
  }

}

@media screen and (max-width: 750px) {
  .y-button {
    padding: 12px 15px;
  }
}
</style>