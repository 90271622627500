<template>
    <span>
<!--        <y-dialog :title="noticeInfo.title" v-model="noticeDialogVis">-->
      <!--            <div class="notice_body" v-html="noticeInfo.content">-->

      <!--            </div>-->
      <!--            <template #footer>-->
      <!--                <y-button @click="execRunLink" v-if="noticeInfo.link" style="margin-right: 10px">{{ noticeInfo.link_text-->
      <!--                }}</y-button>-->
      <!--                <y-button @click="execCloseNotice">我已知晓，不再提示</y-button>-->
      <!--            </template>-->
      <!--        </y-dialog>-->
      <y-dialog title="新版本发布" v-model="noticeDialogVis">
            <div class="notice_body">
              新版本已发布，可点击下发按钮跳转，也可左下角跳转，移动端暂不支持！
            </div>
            <template #footer>
                <y-button @click="execRunLink();$router.push({path:'/new_ui/home/chat'})"
                          style="margin-right: 10px">立即体验</y-button>
                <y-button @click="execCloseNotice()">继续使用老版本</y-button>
            </template>
        </y-dialog>
    </span>
</template>
<script lang="ts" setup>
import {watch, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router';
import notice from '/public/notice.json';

const router = useRouter();
const route = useRoute();

const noticeInfo = notice as INotice;
const noticeDialogVis = ref(false);

interface INotice {
  version: string;
  title: string;
  content: string;
  link: string;
  link_text: string;
  link_type: "router" | "link"
}

const noticeKey = "__l_1_test_dev_version"

const init = () => {
  if (route.path !== '/') {
    return;
  }
  const local_version = localStorage.getItem(noticeKey);

  if (noticeInfo && local_version !== noticeInfo.version) {
    noticeDialogVis.value = true;
  }
}

const execCloseNotice = () => {
  localStorage.setItem(noticeKey, noticeInfo.version);
  noticeDialogVis.value = false;
}

const execRunLink = () => {
  noticeDialogVis.value = false;
  localStorage.setItem(noticeKey, noticeInfo.version);
  if (noticeInfo.link_type === 'router') {
    router.push({path: noticeInfo.link});
  } else {
    window.open(noticeInfo.link);
  }
}

watch(() => route.path, () => {
  ((fn: Function) => {
    let timer: any;
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      clearTimeout(timer);
      fn();
    }, 100);
  })(init)
}, {immediate: true, flush: "post"})


</script>
<style lang="scss" scoped>
.notice_body {
  max-width: 600px;
}

@media screen and (max-width: 750px) {
  .notice_body {
    width: 100%;
  }
}
</style>