<template>
    <select v-model="select" @change="change">
        <slot>
            <option value="">请选择</option>
        </slot>
    </select>
</template>
<script lang="ts" setup>
import {ref, watch} from 'vue'

const emits = defineEmits(["update:modelValue", "change"])
const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: undefined,
    }
});

const select = ref<string | number | undefined>("");


const change = () => {
    emits("update:modelValue", select.value);
    emits("change", select.value);
}

watch(() => props.modelValue, (v) => {
    if (v === undefined) {
        select.value = ""
        return;
    }
    select.value = v;
}, {immediate: true, flush: "post"})
</script>
<style lang="scss" scoped>
select {
  outline: none;
  appearance: none;
  padding: 0.6rem 0.6rem;
  border: 1px solid var(--app-border-color);
  border-radius: 8px;
  color: var(--app-text-color);
  background: var(--app-color-bg);

  &:focus,
  &:focus-visible {
    outline: auto 2px Highlight;
    outline: 4px auto -webkit-focus-ring-color;
    -moz-outline-radius: 4px;
  }

  &:hover {
    cursor: pointer;
  }

  option {

  }
}
</style>