<template>
  <div class="inline relative">
    <div @click="changeActive()" ref="rootRef">
      <slot></slot>
    </div>
    <div ref="contentRef" class="absolute" :class="{'hidden':!data.isActive}">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {onMounted, reactive, ref} from 'vue'

const data = reactive({
  isActive: false,
});
const rootRef = ref<HTMLDivElement>();
const contentRef = ref<HTMLDivElement>();

onMounted(() => {
  calcBottom();
  console.log(rootRef.value?.clientHeight)
})

const calcBottom = () => {
  if (!contentRef.value) {
    return;
  }
  contentRef.value.style.bottom = `-${contentRef.value.clientHeight}px`
}

const changeActive = () => {
  console.log("切换")
  data.isActive = !data.isActive;
  calcBottom();
}

</script>