
export const LottieOptions = {
    width: {
        type: String,
        default: "100px"
    },
    height: {
        type: String,
        default: "100px"
    },
    jsonData: {
        type: Object,
        default: () => null,
    },
    autoPlay: {
        type: Boolean,
        default: true,
    },
    loop: {
        type: Boolean,
        default: true,
    },
    src: {
        type: String,
        default: ""
    },
}