<template>
  <div
      class="w-[90px] relative overflow-hidden bg-[var(--nv-sidebar-bg)] h-full flex flex-col items-center text-[var(--nv-sidebar-text)]">
    <div class="size-[40px] rounded-full overflow-hidden">
      <img src="@/assets/images/ChatGPT-Icon-Logo-PNG.png" alt="" class=""/>
    </div>
    <div class="mt-[20px]">
      <div @click="changeItem(item.path)" v-for="item in sideItemData" :key="item.name"
           class="w-[55px] h-[65px] pt-[5px] justify-center rounded-[10px] mb-[10px] box-border gap-[5px] flex flex-col items-center transition-all hover:bg-[var(--nv-sidebar-item-bg-hover)] hover:cursor-pointer"
           :class="{'bg-[var(--nv-sidebar-item-bg-active)] text-[var(--nv-sidebar-text-active)] shadow cursor-pointer':data.nowSelectPath===item.path}">
        <div class="size-[18px]">
          <svg-icon :name="item.icon" size="18"></svg-icon>
        </div>
        <div class="text-[12px]">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div @click="$router.push({path:'/'})"
         class="absolute bottom-[10px] size-[40px] rounded-full border flex justify-center items-center text-[var(--nv-text-primary)])] cursor-pointer hover:shadow transition-all">
      <svg-icon name="back" size="18"/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {reactive, watch} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter();

const sideItemData = [
  {
    icon: "chat",
    name: "聊天",
    path: "/new_ui/home/chat",
  },
  {
    icon: "nft",
    name: "NFT",
    path: "/new_ui/home/nft",
  },
  {
    icon: "user",
    name: "账号",
    path: "3",
  }
]


const data = reactive({
  nowSelectPath: sideItemData[0].path,
})

const changeItem = (path: string) => {
  data.nowSelectPath = path;
}

watch(() => data.nowSelectPath, (v) => {
  router.push({path: v});
})


</script>