import req from '@/utils/request'

export const chatUserIsOldByUserCode_GET = (user_code:string)=>req.get("/chat/chatUserIsOldByUserCode",{params:{user_code}});

export const chatUserByUserCode_POST = (data:{
    email:string;
    code:string;
    chat_user_code:string;
    password:string;
})=>req.post("/chat/chatUserByUserCode",data);

export const chatUserAuth = (data:{
    email:string;
    password:string;
})=>req.post("/chat/chatUserAuth",data)

export const chatUser_POST = (data:{
    email:string;
    code:string;
    password:string;
    referral_code?:string;
})=>req.post("/chat/chatUser",data);

export const chatUserByCurrent_GET = ()=>req.get("/chat/chatUserByCurrent")

export const chatUserForgetPassword_POST = (data:{
    email:string;
    password:string;
    code:string;
})=>req.post("/chat/chatUserForgetPassword",data);

export const chatUserChangePassword_POST = (data:{
    old_password:string;
    new_password:string;
})=>req.post("/chat/chatUserChangePassword",data);