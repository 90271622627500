import {App} from "vue";

import SvgIcon from './SvgIcon';
import YSwitch from './YSwitch';
import YDialog from './YDialog';
import YDropdown from './YDropdown';
import YButton from './YButton';
import YInput from './YInput';
import YSelect from './YSelect';
import YImagePreview from "./YImagePreview";
import YLottie from './YLottie';

import NV_Input from './NV_UI/Input';
import NV_Button from './NV_UI/Button';
import NV_Tool_Menu from './NV_UI/ToolMenu';
import NV_Tool_Menu_Item from './NV_UI/ToolMenuItem';

export const RegisterGlobalComponents = (app: App) => {
    app.component("svg-icon", SvgIcon);
    app.component("y-switch", YSwitch);
    app.component("y-dialog", YDialog);
    app.component("y-dropdown", YDropdown);
    app.component("y-button", YButton);
    app.component("y-input", YInput);
    app.component("y-select", YSelect);
    app.component("y-image-preview", YImagePreview);
    app.component("y-lottie", YLottie);

    app.component("nv-input", NV_Input);
    app.component("nv-button", NV_Button);
    app.component("nv-tool-menu", NV_Tool_Menu);
    app.component("nv-tool-menu-item", NV_Tool_Menu_Item);
}