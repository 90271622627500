import {Directive} from 'vue'
import {IGlobalDirective} from '@/directive'

export default {
    name: "markdown",
    default: {
        mounted(e, {value}) {
            e.innerHTML = value;
        },
        updated(e, {value}) {
            e.innerHTML = value;
        }
    } as Directive<HTMLDivElement, string>
} as IGlobalDirective