<template>
  <div ref="lottieRef" :style="{width:props.width,height:props.height}"></div>
</template>
<script lang="ts" setup>
import {onMounted, ref} from 'vue'
import {LottieOptions} from './options'
import lottie from 'lottie-web'

const props = defineProps(LottieOptions)

const lottieRef = ref();

onMounted(() => {
  init();
});

const init = () => {
  if (!lottieRef.value) {
    return;
  }

  lottie.loadAnimation({
    container: lottieRef.value,
    renderer: "svg",
    loop: props.loop,
    autoplay: props.autoPlay,
    path: props.src,
    animationData: props.jsonData,
  })
}
</script>