import {createRouter, RouteRecordRaw, createWebHashHistory} from 'vue-router';
import Layout from '@/layout/new_ui/Layout.vue';

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: () => import("@/views/chat/index.vue"),
    },
    {
        path: "/setting",
        component: () => import("@/views/setting/setting.vue"),
    },
    {
        path: "/about",
        component: () => import("@/views/about/about.vue"),
    },
    {
        path: "/version",
        component: () => import("@/views/version/version.vue"),
    },
    {
        path: "/wechat",
        component: () => import("@/views/contact/wechat.vue"),
    },
    {
        path: "/pay",
        component: () => import("@/views/pay/index.vue"),
    },
    {
        path: "/pay-preview",
        component: () => import("@/views/pay/pay-preview.vue"),
    },
    {
        path: "/weChatPay-preview",
        component: () => import("@/views/pay/weChatPay-preview.vue"),
    },
    {
        path: "/pay-success",
        component: () => import("@/views/pay/pay-success.vue"),
    },
    {
        path: "/forget-password",
        component: () => import("@/views/user/forget-password.vue"),
    },
    {
        path: "/change-password",
        component: () => import("@/views/user/change-password.vue"),
    },
    {
        path: "/safe-center",
        component: () => import("@/views/setting/safe-center.vue"),
    },
    {
        path: "/sd-image",
        component: () => import("@/views/aigc/sdImage/index.vue"),
    },
    {
        path: "/voice",
        component: () => import("@/views/voice/voice.vue"),
    },
    {
        path: "/test",
        component: () => import("@/views/test/test.vue"),
    },
    {
        path: "/new_ui/home",
        component: Layout,
        children: [
            {
                path: "index",
                component: () => import ("@/views/new_ui/home/index.vue"),
            },
            {
                path: "chat",
                component: () => import ("@/views/new_ui/chat/index.vue"),
            },
            {
                path: "nft",
                component: () => import ("@/views/new_ui/nft/index.vue"),
            }
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;