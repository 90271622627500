<template>
    <Teleport to="body">
        <div v-if="modelValue" class="image-preview-root">
            <!--遮罩层-->
            <div class="shade-warpper"></div>
            <!--内容层-->
            <div class="content-warpper">
                <!--操作栏-->
                <div class="nav">
                    <div></div>
                    <div @click="emits('update:modelValue',false)" class="right">
                        <svg-icon name="dialog-close"/>
                    </div>
                </div>
                <div class="content">
                    <img :src="data?data[0]: ''"/>
                </div>
                <div>

                </div>
            </div>
        </div>
    </Teleport>
</template>
<script lang="ts" setup>
import {ref, watch,PropType} from 'vue'

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Array as PropType<string[]>,
        default: () => ([])
    }
});


</script>
<style lang="scss" scoped>
.image-preview-root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
}

.shade-warpper {
  z-index: 501;
  //background: red;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.content-warpper {
  position: relative;
  z-index: 502;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  .nav {
    position: sticky;
    top: 0;
    padding: 0 30px;
    align-items: center;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    transition: all .4s;
    flex: 0 0 auto;

    .right {
      transition: all .4s;
      font-size: 22px;

      &:hover {
        cursor: pointer;
        transform: rotate(90deg);
      }
    }
  }

  .content {
    text-align: center;
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 800px;
      height: 100%;
    }
  }


}

@media screen and (max-width: 740px) {
  .content-warpper {
    .content {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
</style>