<template>
    <input class="y-input" :placeholder="placeholder" v-model="inputValue"/>
</template>
<script lang="ts" setup>
import {ref, watch} from 'vue'

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: ""
    },
    placeholder: {
        type: String,
        default: ""
    }
});

const inputValue = ref<string | number>("");

watch(inputValue, (v) => {
    emits("update:modelValue", v)
});

watch(() => props.modelValue, (v) => {
    inputValue.value = v;
}, {immediate: true, flush: "post"})

</script>
<style lang="scss" scoped>
.y-input {
  border: 1px solid var(--app-border-color);
  border-radius: 8px;
  padding: 0.6rem 0.6rem;
  color: var(--app-text-color);
  background: var(--app-color-bg);
  outline: none;
  box-sizing: border-box;
  width: 100%;


  &:focus,
  &:focus-visible {
    outline: auto 2px Highlight;
    outline: 4px auto -webkit-focus-ring-color;
    -moz-outline-radius: 4px;
  }
}

@media screen and (max-width: 750px) {
  .y-input {
    padding: 0.85rem 0.85rem;
  }
}
</style>