import {defineStore} from 'pinia'
import {ref, reactive, onMounted} from 'vue';
import * as cache from '@/utils/cache';
import {chatSetting_GET} from '@/api/chat/chatSetting';

export interface IServerSetting {
    is_gbk: boolean;
    server_version: string;
    model: string;
}

export const useAppStore = defineStore("appStore", () => {
    const theme_cache_key = "_t_theme_key"
    const theme = ref<"light" | "dark">(cache.GetLocalStorageByString(theme_cache_key) || "light");
    const serverSetting = reactive<IServerSetting>({
        is_gbk: false,
        server_version: "",
        model: "",
    })

    const SetTheme = (v: "light" | "dark") => {
        theme.value = v;
        cache.SetLocalStorageByString(theme_cache_key, v);
    };

    const getChatSetting = async () => {
        const {data} = await chatSetting_GET();
        Object.assign(serverSetting, data.data);
    }

    onMounted(() => {
        getChatSetting();
    })

    return {
        theme,
        serverSetting,
        SetTheme
    }
});
