import {Directive,ref} from 'vue'
import {IGlobalDirective} from '@/directive'

export default {
    name: "inputFloat",
    default: {
        mounted(e, {value,oldValue :_oldValue},vnode) {
            const inputLocking = ref(false);

            let input = vnode.el;
            input.addEventListener('compositionstart', () => {
                inputLocking.value = true
            })
            input.addEventListener('compositionend', () => {
                inputLocking.value = false
                input.dispatchEvent(new Event('input'))
            })
            input.addEventListener('input', () => {
                if(inputLocking.value) {
                    return;
                }
                let oldValue = input.value;
                let newValue = input.value;
                newValue = newValue.replace(/[^\d.]/g, '');
                newValue = newValue.replace(/^\./g, '');
                newValue = newValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
                newValue = newValue.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                if(newValue) {
                    let arr = newValue.split('.')
                    newValue = Number(arr[0]) + (arr[1] === undefined ? '' : '.' + arr[1]) // 去掉开头多余的0
                }
                // 判断是否需要更新，避免进入死循环
                if(newValue !== oldValue) {
                    input.value = parseFloat(newValue)
                    input.dispatchEvent(new Event('input')) // 通知v-model更新
                }
            })
            // input 事件无法处理小数点后面全是零的情况 因为无法确定用户输入的0是否真的应该清除，如3.02。放在blur中去处理
            input.addEventListener('blur', () => {
                let oldValue = input.value;
                let newValue = input.value;
                if(newValue) {
                    newValue = Number(newValue).toString()
                }
                // 判断是否需要更新，避免进入死循环
                if(newValue !== oldValue) {
                    input.value = parseFloat(newValue)
                    input.dispatchEvent(new Event('input')) // 通知v-model更新
                }
            })

        },
    } as Directive<HTMLDivElement, string>
} as IGlobalDirective
