export const SetLocalStorageByString = (key: string, v: string) => {
    localStorage.setItem(key, v);
}

export const GetLocalStorageByString = <T extends string>(key: string): T => {
    return (localStorage.getItem(key) || "") as T;
}

export const RemoveLocalStorage = (key: string) => {
    return localStorage.removeItem(key);
}

export const SetLocalStorageByObject = (key: string, v: any) => {
    try {
        localStorage.setItem(key, JSON.stringify((v)));
    } catch (e) {
        console.error("cache error key:", key);
    }
}

export const GetLocalStorageByObject = <T extends Record<string, any>>(key: string): T => {
    let obj = undefined;
    try {
        const objStr = localStorage.getItem(key);
        if (objStr) {
            obj = JSON.parse(objStr);
        }
    } catch (e) {
        console.error("cache error key:", key);
    }

    return obj as T
}