import {createApp} from 'vue'
import {createPinia} from 'pinia'
import Router from "@/router"
import App from './App.vue'
import {RegisterGlobalComponents} from '@/components';
import {RegisterGlobalDirective} from '@/directive';
import 'virtual:svg-icons-register'
import "@/assets/styles/__var.css";
import "@/assets/styles/__var_new.css";
import "@/assets/styles/tailwind.css";

const app = createApp(App)
app.use(createPinia())
app.use(Router)
RegisterGlobalDirective(app)
RegisterGlobalComponents(app)
app.mount('#app')
