<template>
    <div class="app">
        <router-view/>
        <GlobalNotice/>

        <y-dialog v-model="bindUserDialogVis" width="380px" :close-on-click-modal="false"
                  :show-close="false"
                  :title="userIsOld?'请先完善信息【老用户专属福利】':'注册'">
            <form novalidate ref="bindUserFormRef" style="width:100%">
                <y-input v-model.trim="registerUserForm.email" id="email" name="email" maxlength="100" type="email"
                         required style="margin-bottom:15px;"
                         placeholder="邮箱地址"/>
                <y-input v-model.trim="registerUserForm.password" type="password" maxlength="100" required
                         style="margin-bottom:15px;"
                         placeholder="密码"/>
                <y-input v-model.trim="registerUserForm.confirm_password" type="password" maxlength="100"
                         required style="margin-bottom:15px;"
                         placeholder="确认密码"/>
                <div style="display:flex;gap:10px;">
                    <y-input @keydown.enter="submitRegisterUser" v-model.trim="registerUserForm.code" required
                             placeholder="验证码" maxlength="20"/>
                    <!--                    :disabled="codeCountDown"-->
                    <y-button type="button" style="flex:0 0 auto;width:120px" @click="sendCode">
                        {{ codeCountDown ? `重新发送(${codeCountDown})s` : isSendCode ? '重新发送' : '发送验证码' }}
                    </y-button>
                </div>
                <div class="link-warpper" style="margin-top:10px;">
                    <u @click="giveUpBind">放弃福利</u>
                </div>
            </form>
            <template #footer>
                <y-button type="primary" style="width:100%;" @click="submitRegisterUser">提交</y-button>
            </template>
        </y-dialog>

        <y-dialog width="380px" v-model="loginUserDialogVis" :close-on-click-modal="false" :show-close="false"
                  :title="loginFormType==='login'?'登录':'注册'">
            <form novalidate ref="loginFormRef" style="width:100%">
                <y-input @keydown.enter=" loginFormType==='login'?submitLoginUser():submitRegisterUser()"
                         v-model.trim="registerUserForm.email" name="email" maxlength="100" type="email"
                         required style="margin-bottom:15px;"
                         placeholder="邮箱地址"/>
                <y-input @keydown.enter=" loginFormType==='login'?submitLoginUser():submitRegisterUser()"
                         v-model.trim="registerUserForm.password" type="password" maxlength="100" required
                         style="margin-bottom:15px;"
                         placeholder="密码"/>
                <template v-if="loginFormType==='register'">
                    <y-input v-model.trim="registerUserForm.confirm_password" type="password" maxlength="100"
                             required style="margin-bottom:15px;"
                             placeholder="确认密码"/>
                    <div style="display:flex;gap:10px;margin-bottom:15px;">
                        <y-input v-model.trim="registerUserForm.code" required placeholder="验证码" maxlength="20"/>
                        <y-button type="button" :disabled="codeCountDown" style="flex:0 0 auto;width:120px"
                                  @click="sendCode">
                            {{ codeCountDown ? `重新发送(${codeCountDown})s` : isSendCode ? '重新发送' : '发送验证码' }}
                        </y-button>
                    </div>
                </template>
                <div class="link-warpper">
                    <u @click="loginUserDialogVis=false;userStore.SetShowAuth(false);$router.push({path:'/forget-password',query:{email:registerUserForm.email}})"
                       v-if="loginFormType !== 'register'">忘记密码</u>
                    <u @click="loginFormType==='login'?loginFormType='register':loginFormType='login'">{{
                        loginFormType === 'register' ? '返回登录' : '注册账号'
                        }}</u>
                </div>
            </form>
            <template #footer>
                <y-button type="primary" style="width:100%;"
                          @click="loginFormType==='login'?submitLoginUser():submitRegisterUser()">
                    {{ loginFormType === 'login' ? '登录' : '注册' }}
                </y-button>
            </template>
        </y-dialog>
    </div>
</template>
<script lang="ts" setup>
import {ref, onMounted, reactive, watch} from "vue";
import {useAppStore} from '@/store/app';
import {useUserStore} from '@/store/user';
import {useChatStore} from '@/store/chat'
import message from '@/components/YMessage'
import GlobalNotice from './layout/components/GlobalNotice.vue';
import {chatUserIsOldByUserCode_GET, chatUser_POST, chatUserByUserCode_POST} from '@/api/chat/chatUser';
import {sendCode_POST} from '@/api/email'
import {useRouter, useRoute} from 'vue-router';

const appStore = useAppStore();
const userStore = useUserStore();
const chatStore = useChatStore();
const router = useRouter();
const route = useRoute();

const bindUserDialogVis = ref(false);
const loginUserDialogVis = ref(false);
const loginFormType = ref<"register" | "login">("login")
const loginFormRef = ref<HTMLFormElement>();
const bindUserFormRef = ref<HTMLFormElement>();
const registerUserForm = reactive({
    email: "",
    user_code: "",
    password: "",
    confirm_password: "",
    code: "",
});
const loading = ref(false);
const userIsOld = ref(false);
const codeCountDown = ref(0);
const isSendCode = ref(false);
const urlSearchInfo = reactive<any>({});

onMounted(() => {
    // 设置网站标题
    document.title = import.meta.env.VITE_APP_TITLE;

    // 检测是否启用了暗黑主题
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // 初始化时检测一次主题模式
    if (darkModeQuery.matches) {
        appStore.SetTheme("dark")
    }else {
        appStore.SetTheme("light")
    }

    // 监听主题模式的变化
    darkModeQuery.addEventListener('change', (event) => {
        if (event.matches) {
            appStore.SetTheme("dark")
        } else {
            appStore.SetTheme("light")
        }
    });

    watch(() => appStore.theme, (v) => {
        initTheme(v);
    }, {immediate: true, flush: "post"});

    // 移动端active类适配
    document.body.addEventListener('touchstart', function () {
    });

    // 判断是否邀请链接
    Object.assign(urlSearchInfo, parseQueryString(window.location.href))
    if (urlSearchInfo["referral_code"]) {
        loginFormType.value = "register";
    }
});

const parseQueryString = (url: string) => {
    let queryParams: Record<string, any | any[]> = {};
    const queryString = url.split('?')[1];

    if (queryString) {
        const pairs = queryString.split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            const key = decodeURIComponent(pair[0]);
            const value = decodeURIComponent(pair[1] || '');

            if (queryParams[key]) {
                if (Array.isArray(queryParams[key])) {
                    queryParams[key].push(value);
                } else {
                    queryParams[key] = [queryParams[key], value];
                }
            } else {
                queryParams[key] = value;
            }
        }
    }

    return queryParams;
}

const initTheme = (type: "light" | "dark") => {
    const body = document.querySelector("body");
    const lighlightLink = document.querySelector("#lighlightLink") as any;
    if (type === "light") {
        body?.classList.remove("dark");
        body?.classList.add("light");
        // import("highlight.js/styles/lightfair.css");
        if (lighlightLink) {
            lighlightLink.href = "atom-one-light.css"
        }

    } else {
        body?.classList.remove("light");
        body?.classList.add("dark");
        // import("highlight.js/styles/dark.css");
        if (lighlightLink) {
            lighlightLink.href = "atom-one-dark.css"
        }
    }
}

const giveUpBind = () => {
    if (!confirm("确定放弃福利？")) {
        return
    }

    bindUserDialogVis.value = false;
    loginUserDialogVis.value = true;
}
const submitRegisterUser = async () => {
    if (!bindUserFormRef.value?.checkValidity()) {
        if (registerUserForm.email && !/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(registerUserForm.email)) {
            message.info("邮箱格式不正确")
            return
        }
        message.info("请先将信息填写完整!")
        return
    }

    if (registerUserForm.password.length < 6) {
        message.info("密码长度不能少于6个字符！")
        return
    }

    if (registerUserForm.password !== registerUserForm.confirm_password) {
        message.info("两次密码不一致！")
        return
    }

    // 判断是否老用户注册
    if (userIsOld.value) {
        await chatUserByUserCode_POST({
            email: registerUserForm.email,
            password: registerUserForm.password,
            code: registerUserForm.code,
            chat_user_code: chatStore.userCode,
        })
        // 调用登录
        await userStore.Login(registerUserForm)
        message.info("绑定成功！");
        codeCountDown.value = 0;
        bindUserDialogVis.value = false;
    } else {
        // 判断是否有邀请Code
        const referralCode = urlSearchInfo["referral_code"]
        await chatUser_POST({
            email: registerUserForm.email,
            password: registerUserForm.password,
            code: registerUserForm.code,
            referral_code: referralCode,
        })
        // 调用登录
        await userStore.Login(registerUserForm)
        message.info("注册成功！");
        codeCountDown.value = 0;
        loginUserDialogVis.value = false;
    }

    setTimeout(() => {
        window.location.reload()
    }, 1000)

}

const submitLoginUser = async () => {
    if (!loginFormRef.value?.checkValidity()) {
        if (registerUserForm.email && !/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(registerUserForm.email)) {
            message.info("邮箱格式不正确")
            return
        }
        message.info("请先将信息填写完整!")
        return
    }

    // 调用登录
    await userStore.Login(registerUserForm)
    message.info("登录成功!")
    loginUserDialogVis.value = false;
    setTimeout(() => {
        window.location.reload()
    }, 1000)
}

const sendCode = async () => {
    if (!registerUserForm.email) {
        message.info("请先填写邮箱地址！")
        return
    }

    if (!/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(registerUserForm.email)) {
        message.info("邮箱格式不正确")
        return
    }
    await sendCode_POST(registerUserForm.email);
    message.info("验证码发送成功，请检查您的邮箱！");
    codeCountDown.value = 60;
    isSendCode.value = true;
    const timer = setInterval(() => {
        if (codeCountDown.value <= 1) {
            clearInterval(timer)
        }
        codeCountDown.value--;

    }, 1000);
}

watch(() => userStore.showAuth, async (v) => {
    if (v) {
        const {data} = await chatUserIsOldByUserCode_GET(chatStore.userCode)
        if (data.data.is_old) {
            userIsOld.value = true;
            bindUserDialogVis.value = true;
        } else {
            loginUserDialogVis.value = true;
        }
    } else {

    }
}, {deep: true})
</script>
<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.link-warpper {
  display: flex;
  gap: 8px;
  justify-content: right;

  u {
    font-size: 0.9rem;
    color: #409EFF;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>