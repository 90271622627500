import {App, Directive} from 'vue'

import vMarkdown from './src/vMarkdown/vMarkdown';
import vInputFloat from './src/vInputFloat/vInputFloat';
import vLoading from './src/vLoading/vLoading';

export interface IGlobalDirective {
    name: string;
    default: Directive
}

export const RegisterGlobalDirective = (app: App) => {
    app.directive(vMarkdown.name,vMarkdown.default);
    app.directive(vInputFloat.name,vInputFloat.default);
    app.directive(vLoading.name,vLoading.default);
}