import {defineStore} from 'pinia'
import {ref} from 'vue';
import * as cache from '@/utils/cache';
import {chatUserAuth,chatUserByCurrent_GET} from '@/api/chat/chatUser';
import {useRouter} from 'vue-router';
import {useChatStore} from './chat'
export const useUserStore = defineStore("userStore", () => {
    const chatStore = useChatStore();
    const router = useRouter();
    const TOKEN_STORE_KEY = "__t"
    const EMAIL_STORE_KEY = "__email"
    const email = ref(cache.GetLocalStorageByString(EMAIL_STORE_KEY)||"")
    const token = ref(cache.GetLocalStorageByString(TOKEN_STORE_KEY) || undefined);
    const showAuth = ref(false);
    const SetToken = (v: string) => {
        cache.SetLocalStorageByString(TOKEN_STORE_KEY, v);
        token.value = v;
    }

    const SetEmail = (v: string) => {
        cache.SetLocalStorageByString(EMAIL_STORE_KEY, v);
        email.value = v;
    }

    const SetShowAuth = (v: boolean) => {
        showAuth.value = v;
    }

    const LoginOut = () => {
        token.value = undefined;
        cache.RemoveLocalStorage(TOKEN_STORE_KEY);
        router.push("/")
    }

    const Login = async (loginForm: { email: string, password: string}) => {
        try{
            const {data} = await chatUserAuth(loginForm)
            SetToken(data.data.token);
            SetEmail(loginForm.email);
            // 获取用户信息
            const {data:userInfo} = await chatUserByCurrent_GET();
            await chatStore.SetUserCode(userInfo.data.chat_user_code)
            return Promise.resolve("success")
        }catch(err){
            return Promise.reject(err)
        }
    }

    return {
        token,
        showAuth,
        email,
        SetShowAuth,
        SetToken,
        LoginOut,
        Login,
    }
});
