<template>
  <div class="inline">
    <div>

    </div>
    <div>
      <input v-model="data.value" :placeholder="props.placeholder"
             class="bg-[var(--nv-bg-primary)] border border-[var(--nv-border-primary)] rounded-[4px] py-[4px] px-[8px] text-[var(--app-font-size)]"/>
    </div>
    <div>

    </div>
  </div>
</template>
<script lang="ts" setup>
import {defineComponent, reactive, watch} from "vue";

defineComponent({
  name: "nv-input",
})

const data = reactive({
  value: "",
})
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  }
})
const emits = defineEmits(["update:model-value"])


watch(() => props.modelValue, (v) => {
  data.value = v;
}, {immediate: true})
watch(() => data.value, (v) => {
  emits("update:model-value", v);
})
</script>