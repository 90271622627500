<template>
  <div class="h-[60px] flex items-center px-[25px] bg-[var(--nv-navbar-bg)] text-[var(--nv-navbar-text)]">
    <!--Logo区域-->
    <div class="font-bold text-[25px]">
      Agent-AI
    </div>
  </div>
</template>
<script lang="ts" setup>

</script>