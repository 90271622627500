<template>
  <div :class="full?'w-full':'inline'">
    <button class="border px-[15px] min-h-[33px] hover:shadow flex items-center justify-center gap-[8px]" :class="calcClass()">
      <slot></slot>
    </button>
  </div>
</template>
<script lang="ts" setup>
import {defineComponent, PropType} from "vue";

defineComponent({
  name: "nv-button"
})

const props = defineProps({
  type: {
    type: String as PropType<"primary" | "danger" | "default">
  },
  round: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  circle: {
    type: Boolean,
    default: false,
  }
});

const calcClass = () => {
  let className = ``
  switch (props.type) {
    case "primary":
      className += `bg-[var(--nv-button-bg-primary)] text-[var(--nv-button-text-primary)] `
      break;
    case "danger":
      className += `bg-[var(--nv-button-bg-danger)] text-[var(--nv-button-text-danger)] `
      break;
    default:
      className += `bg-[var(--nv-bg-default)] `
      break;
  }

  if (props.round) {
    className += `rounded-[var(--nv-button-round)] `
  }

  if (props.full) {
    className += `w-full `
  }

  if (props.circle) {
    className += `rounded-full py-0 px-0 min-w-[33px] min-h-[33px] flex justify-center items-center `
  }
  return className
}
</script>