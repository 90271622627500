<template>
  <div>
      dropdown
  </div>
</template>
<script lang="ts" setup>

</script>
<style lang="scss" scoped>
.d{
  position: relative;
}
</style>