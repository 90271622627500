import {Directive} from 'vue'
import {IGlobalDirective} from '@/directive'
import lottie, {AnimationItem} from 'lottie-web';
import dataJson from "./data.json";
import "./vLoading.scss";

interface ILoadingDivElement extends HTMLDivElement {
    $vLoadingSvg: AnimationItem;
    $vLoadingRootDom: HTMLDivElement;
    $parentDefaultPosition: string;
}

const computedDiv = (div: HTMLDivElement, e: ILoadingDivElement) => {
    div.style.position = "absolute";
    div.style.background = "transparent"
    // div.style.left = `${e.offsetLeft}px`;
    // div.style.right = `${document.body.clientWidth - (e.clientWidth + e.offsetLeft)}px`;
    // div.style.bottom = `${document.body.clientHeight - e.clientHeight - e.offsetTop}px`;
    // div.style.top = `${e.offsetTop}px`;
    div.style.left = "0";
    div.style.right = "0";
    div.style.bottom = "0";
    div.style.top = "0";

    return div;
}
export default {
    name: "loading",
    default: {
        mounted(e, {value}) {
            const domId = new Date().getTime().toString();
            let div = document.createElement("div");
            div.id = domId;
            div.className = "v-loading";
            div = computedDiv(div, e);
            e.$parentDefaultPosition = e.style.position;
            e.$vLoadingRootDom = div;
            e.$vLoadingSvg = lottie.loadAnimation<"svg">({
                container: div,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: dataJson,
                rendererSettings: {
                    hideOnTransparent: true,
                    // viewBoxSize: "1rem",
                },
            });

            if (value) {
                e.style.position = "relative";
                e.appendChild(div)
            }
        },
        updated(e, {value}) {
            e.$vLoadingRootDom = computedDiv(e.$vLoadingRootDom, e);
            if (value) {
                e.style.position = "relative";
                e.appendChild(e.$vLoadingRootDom);
            } else if (e.contains(e.$vLoadingRootDom)) {
                e.style.position = e.$parentDefaultPosition;
                e.removeChild(e.$vLoadingRootDom);
            }
        },
        unmounted(e) {
            e.$vLoadingSvg.destroy();
        }
    } as Directive<ILoadingDivElement, boolean>
} as IGlobalDirective
