<template>
    <div class="y-switch-warpper">
        <div @click="active = !active" :class="{'switch-content__open':active}" class="switch-content">
            <div class="tip"></div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import "./__var.css";
import {ref, watch} from 'vue'

const emits = defineEmits(["update:modelValue"])
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    }
})

const active = ref(false);

watch(() => props.modelValue, (v) => {
    active.value = v;
}, {immediate: true, flush: "post"});

watch(active, (v) => {
    emits("update:modelValue", v);
}, {flush: "post"})

</script>
<style lang="scss" scoped>
.y-switch-warpper {
  height: 1.5rem;
  width: 3.125rem;
  position: relative;
  z-index: 1;
}

.switch-content {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: var(--y-switch-off-color);
  display: flex;
  align-items: center;
  transition: all .3s;

  &:hover {
    cursor: pointer;
  }
}

.switch-content__open {
  background: var(--y-switch-on-color);
}

.switch-content__open .tip {
  left: 1.6878rem;
  transition: all .3s;
}

.tip {
  border-radius: 50%;
  background: var(--y-switch-tip-bg-color);
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0.1875rem;
  transition: all .3s;
}

</style>