import {defineStore} from 'pinia'
import {ref, computed, reactive} from 'vue';
import * as cache from '@/utils/cache';
import {GenerateUID} from '@/utils/uuid'


export interface ISetting {
    temperature: number;
    network: boolean;
    model: string;
    frequency_penalty: number;
    presence_penalty: number;
    vector_query: boolean;
    userSetting: IUserSetting;
}

export interface IUserSetting {
    /** 是否开启token续签，开启后会自动分隔合理token **/
    token_renew: boolean;
    /** 回车方式 **/
    enter_type: number;
}


export const useChatStore = defineStore("chatStore", () => {
    const user_code_cache_key = "_t_user_code_key"
    const setting_cache_key = "_t_setting_key"
    const userCode = ref<string>(cache.GetLocalStorageByString(user_code_cache_key) || GenerateUID());
    const sessionCode = ref<string>("");
    const openaiConversationId = ref<string>("");
    const parentMessageId = ref<string>("");
    const initChat = ref<boolean>(true);
    const newMsg = ref<boolean>(false);
    const refreshSession = ref<boolean>(false);
    const refreshSessionByMust = ref<boolean>(false);
    const inputLoading = ref<boolean>(false);
    const commandLoading = ref<boolean>(false);
    const setting = reactive<ISetting>(
        cache.GetLocalStorageByObject<ISetting>(setting_cache_key) ||
        {
            temperature: 0.5,
            network: false,
            model: "gpt-3.5",
            presence_penalty: 0,
            frequency_penalty: 0,
            vector_query: false,
            userSetting: {
                token_renew: true,
                enter_type: 1
            }
        });
    // TODO: 整体架构未调整，临时方案
    const chatContentLoading = ref<boolean>(false)

    const GetUserCode = computed<string>(() => {
        const code = cache.GetLocalStorageByString(user_code_cache_key);
        if (!code) {
            const newCode = GenerateUID();
            userCode.value = newCode;
            cache.SetLocalStorageByString(user_code_cache_key, newCode);
        }

        return userCode.value;
    });

    const GetSetting = computed(() => setting);
    const SetSetting = (v: ISetting) => {
        cache.SetLocalStorageByObject(setting_cache_key, v);
        Object.assign(setting, v);
    }


    const SetUserCode = (v: string) => {
        cache.SetLocalStorageByString(user_code_cache_key, v);
        userCode.value = v;
    }

    const SetNewMsg = (v: boolean) => {
        newMsg.value = v;
    }

    const SetParentMessageId = (v: string) => {
        parentMessageId.value = v;
    }

    const SetOpenaiConversationId = (v: string) => {
        openaiConversationId.value = v;
    }

    const SetInitChat = (v: boolean) => {
        initChat.value = v;
    }
    const SetSessionCode = (code: string) => {
        sessionCode.value = code;
    }

    const GetSessionCode = computed(() => {
        // if (!sessionCode.value) {
        //     sessionCode.value = GenerateUID();
        // }
        return sessionCode.value
    })

    const SetRefreshSession = (v: boolean) => {
        refreshSession.value = v;
    }

    const SetRefreshSessionByMust = (v: boolean) => {
        refreshSessionByMust.value = v;
    }

    const SetInputLoading = (v: boolean) => {
        inputLoading.value = v;
    }

    const SetCommandLoading = (v: boolean) => {
        commandLoading.value = v;
    }

    const GetInputEnterTypeData = () => {
        return [
            {
                id: 1,
                key1: "shift",
                key2: "enter",
            },
            {
                id: 2,
                key1: "ctrl",
                key2: "enter",
            }
        ]
    }

    const GetEnterType = computed(() => {
        return GetInputEnterTypeData().find(f => f.id === setting.userSetting.enter_type)
    })

    return {
        userCode,
        sessionCode,
        initChat,
        newMsg,
        refreshSession,
        refreshSessionByMust,
        inputLoading,
        setting,
        GetSetting,
        GetUserCode,
        chatContentLoading,
        commandLoading,
        openaiConversationId,
        SetOpenaiConversationId,
        SetSetting,
        SetInputLoading,
        SetCommandLoading,
        SetRefreshSession,
        SetRefreshSessionByMust,
        SetInitChat,
        SetNewMsg,
        SetSessionCode,
        SetUserCode,
        SetParentMessageId,
        parentMessageId,
        GetSessionCode,
        GetInputEnterTypeData,
        GetEnterType,
    }
});
