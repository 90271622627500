import {ref} from 'vue'
import "./index.css";

const msgDom = document.createElement("div")
const showMessage = ref(false)
const init = () => {
    msgDom.className = "y-message";
    document.body.appendChild(msgDom);
}

const createMessage = (msg: string) => {
    if (showMessage.value) {
        return
    }
    msgDom.innerText = msg;
    msgDom.style.display = "black"
    msgDom.style.left = `calc(50% - ${msgDom.clientWidth / 2}px)`
    msgDom.style.bottom = "15px"
    msgDom.style.visibility = "unset";
    showMessage.value = true;
    setTimeout(() => {
        msgDom.style.bottom = "-100px"
        msgDom.style.visibility = "hidden";
        showMessage.value = false;
    }, 1200)

}
const info = (msg: string) => {
    createMessage(msg)
}

init();

export default {
    info
}