<template>
    <Teleport to="body">
        <div v-show="dialogVis" class="y-dialog">
            <!--遮罩层-->
            <div @click.stop="closeOnClickModal?closeDialog():undefined" class="dialog-mask-warpper"></div>
            <div class="dialog-body-warpper" :style="dialogBodyWarpperStyle">
                <div class="dialog-nav-warpper">
                    <div class="title">
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </div>
                    <div class="operate">
                        <svg-icon v-if="showClose" @click="closeDialog" class="operate-item" name="close"/>
                    </div>
                </div>
                <div class="dialog-content">
                    <slot></slot>
                </div>
                <div class="dialog-footer" :style="footerStyle">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script lang="ts" setup>
import {ref, watch, reactive, computed, PropType} from 'vue'

const emits = defineEmits(["update:modelValue", "open", "close"]);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: ""
    },
    showClose: {
        type: Boolean,
        default: true,
    },
    closeOnClickModal: {
        type: Boolean,
        default: true,
    },
    footerAlign: {
        type: String as PropType<"left" | "center" | "right">,
        default: "right"
    },
    width: {
        type: String,
        default: undefined,
    }
})

const dialogVis = ref(false);
const dialogBodyWarpperStyle = reactive({
    minWidth: props.width,
})
const footerStyle = computed(() => ({justifyContent: props.footerAlign}))


watch(() => props.modelValue, (v) => {
    dialogVis.value = v;
}, {immediate: true, flush: "post"})

watch(dialogVis, (v) => {
    if (v) {
        emits("open")
    } else {
        emits("close")
    }
    emits("update:modelValue", v);
})

const closeDialog = () => {
    dialogVis.value = false;
}

</script>
<style lang="scss" scoped>
.y-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-mask-warpper {
    z-index: 301;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    opacity: 0.5;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .dialog-body-warpper {
    z-index: 302;
    position: absolute;
    min-width: 30%;
    min-height: 30%;
    overflow: auto;
    background: var(--app-color-bg);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 0;
  }

  @media screen and (max-width: 750px) {
    .dialog-body-warpper {
      min-width: 90% !important;
    }
  }

  .dialog-nav-warpper {
    padding: 0 1.25rem;
    border-bottom: 1px solid var(--app-border-color);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    line-height: 3.75rem;
    box-sizing: border-box;

    .operate {

      .operate-item {
        font-size: 1.375rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .dialog-content {
    padding: 1.25rem;
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
  }

  .dialog-footer {
    padding: 1.25rem;
    text-align: right;
    flex: 0 0 auto;
    display: flex;
    box-sizing: border-box;
  }
}
</style>